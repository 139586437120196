/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: 'boowie';
    src: url('./assets/fonts/BOOWIE.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

body {
    background: #434343;
    font-family: 'boowie', 'arial', sans-serif;
    font-size: 20px;
    color: #000;
    font-display: swap;
}

p, li {
    font-family: sans-serif;
    font-size: 18px;
    text-align: justify;
}

h1, h2, h3, h4 {
    margin-top: 10px;
    margin-bottom: 0px;
    font-weight: bold;
    color: #000;
}

h1 {
    font-size: 26px;
}

h2 {
    font-size: 22px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

h5 {
    font-size: 14px;
}

.home-logo {
    background: #434343;
    border-radius: 5px;
}

.center {
    text-align: center;
}

.logo {
    width: 235px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.link {
    color: #000;
    text-decoration: none;
}

.link:hover {
    color: #000;
    font-size: 17px;
    font-weight: bold;
}

.navbar {
    background: #434343;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.nav-link {
    color: #fff;
}

.nav-link:hover, .config-icon:hover {
    color: #D77A0F;
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link,
.active {
    color: #fff;
    font-weight: bold;
}

.bg-dark {
    background-color: #434343 !important;
}

.config-icon {
    background: #434343;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 3px 5px;
}

.icon-small {
    height: 30px;
    width: 30px;
}

.icon-medium {
    height: 45px;
    width: 45px;
}

.icon-active bi {
    border: 2px solid #fff;
}

.align-left {
    float: left;
}

.form-check-input {
    background: #555555;
}

.form-check-input:checked {
    background: #434343;
    border-color: #fff;
}

.overlay {
    text-align: center;
    align-items: center;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    opacity: .8;
    background-color: #434343;
}

.overlay .align {
    margin-top: 25%;
}

.overlay img {
    width: 50%;
    max-width: 150px;
}

.dark-modal .modal-content {
    background-color: #434343;
    color: #fff;
}

.dark-modal .btn-close {
    border: 2px solid #111111;
}

.btn-outline-dark {
    border: 2px solid #fff;
    font-weight: bold;
    font-size: 15px;
    font-family: sans-serif;
    color: #fff;
    background-color: #434343;
}

.font-bold {
    font-weight: bold;
}

.btn-white {
    color: #fff;
    background: #D77A0F;
}

.btn-white:hover {
    transition: 0.1s;
    color: #fff;
    background: #bd6b0d;
}

.btn-white:disabled {
    border: 0;
    color: #fff;
    background-color: #D77A0F !important;
}

.button-config bi {
    display: inline-block;
    border-left: 1px solid white;
    padding-left: 7px;
    height: 29px;
}

.quantity {
    background-color: #434343 !important;
    color: #fff;
    text-align: center;
    width: 85px;
    display: inline;
    height: 30px;
    padding-top: 10px;
    /* padding-left: 25px; */
}

.quantity-btn {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
}

.quantity-btn:hover {
    color: #D77A0F;
}

.quantity-box {
    position: relative;
}

.quantity-left {
    position: absolute;
    top: -4px;
    left: -3px;
}

.quantity-right {
    position: absolute;
    top: -4px;
    left: 53px;
}

.accordion {
    color: #fff;
    font-family: sans-serif;
}

.accordion-body {
    background: #555555;
    color: #fff;
}

.form-message-error {
    font-size: 12px;
    color: #ff8282;
}

.form-label {
    font-size: 16px;
}

.about-circle {
    border: 2px solid #fff;
    border-radius: 50px;
    padding: 3px;
    font-weight: bold;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 12px;
}

.special-price {
    opacity: .5;
}

.colombia-small {
    width: 40px;
    margin-right: 10px;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.common-font {
    font-family: sans-serif;
    font-size: 18px;
    text-align: justify;
}

.c-disabled {
    color: #919191;
}

.accordion-header button {
    background: #434343;
    color: white;
}

.color-two {
    width: 0;
    height: 0;
    border-left: 23px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 23px solid;
}

.show-discount {
    padding-top: 2px;
    width: 50px;
    height: 50px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 15px;
    background: #D77A0F;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    margin-top: -55px;
    margin-left: 3rem;
}

.carousel-indicators {
    margin-bottom: 0 !important;
}

#products.accordion-body {
    padding: 5px !important;
}

@media (max-width: 990px) {
    .button-config {
        margin-top: 10px;
    }
}

@media (max-width: 700px) {
    .show-discount {
        margin-left: 2.5rem;
    }
}